<template>
    <div class="captcha-container">
        <div class="image-box">
            <img :src="bgImage" alt="背景图" class="bg-image" />
            <img :src="blockImage" alt="滑块" class="block-image" :style="blockStyle" />
        </div>
        <div class="slider-box">
            <div class="slider-track" @mousedown="startSlide">
                <div class="slider" :style="{ left: sliderX + 'px' }"></div>
            </div>
            <p>{{ message }}</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            captchaId: "",
            bgImage: "",
            blockImage: "",
            blockY: 0,
            sliderX: 0,
            isSliding: false,
            message: "请拖动滑块完成验证",
        };
    },
    computed: {
        blockStyle() {
            return {
                top: this.blockY + "px",
                left: this.sliderX + "px",
            };
        },
    },
    mounted() {
        this.fetchCaptcha();
    },
    methods: {
        initSlide() {
            this.fetchCaptcha(); // 失败后刷新验证码
        },
        initSlideX() {
            this.sliderX = 0;
        },
        async fetchCaptcha() {
            // const response = await axios.get("http://localhost:8080/api/captcha/generate");
            this.$request('/app-api/api/captcha/generate', {}, 'get').then(res => {
                console.log(res);
                this.captchaId = res.data.captchaId;
                this.bgImage = res.data.bgImage;
                this.blockImage = res.data.blockImage;
                this.blockY = res.data.blockY;
            })

        },
        startSlide(event) {
            this.isSliding = true;
            const startX = event.clientX;
            const sliderTrack = event.target;

            const moveHandler = (moveEvent) => {
                if (!this.isSliding) return;
                const deltaX = moveEvent.clientX - startX;
                this.sliderX = Math.max(0, Math.min(deltaX, 250)); // 限制滑动范围
            };

            const upHandler = async () => {
                this.isSliding = false;
                document.removeEventListener("mousemove", moveHandler);
                document.removeEventListener("mouseup", upHandler);

                // this.$request('/app-api/api/captcha/verify', {
                //     captchaId: e.captchaId,
                //     x: this.sliderX,
                //     mobile: this.nowCheckMenu == 1 ? this.personalForm.mobile : this.businessForm
                //         .accountRegistration,
                //     type: 'login',
                //     scene: 50
                // }, 'get').then(res => {
                //     console.log(res);
                //     this.captchaId = res.data.captchaId;
                //     this.bgImage = res.data.bgImage;
                //     this.blockImage = res.data.blockImage;
                //     this.blockY = res.data.blockY;
                // })
                this.$emit('endSlide', {
                    captchaId: this.captchaId,
                    sliderX: (this.sliderX / 2)
                })
                // 验证
                // const verifyResponse = await axios.post("http://localhost:8080/api/captcha/verify", {
                //     captchaId: this.captchaId,
                //     x: this.sliderX,
                // });
                // this.message = verifyResponse.data.message;
                // if (verifyResponse.data.success) {
                //     alert("验证成功！");
                // } else {
                //     this.sliderX = 0;
                //     this.fetchCaptcha(); // 失败后刷新验证码
                // }
            };

            document.addEventListener("mousemove", moveHandler);
            document.addEventListener("mouseup", upHandler);
        },
    },
};
</script>

<style scoped>
.captcha-container {
    width: 300px;
    margin: 0 auto;
}

.image-box {
    position: relative;
    width: 300px;
    height: 150px;
}

.bg-image,
.block-image {
    position: absolute;
}

.block-image {
    width: 50px;
    height: 50px;
}

.slider-box {
    margin-top: 10px;
}

.slider-track {
    width: 300px;
    height: 40px;
    background: #ddd;
    position: relative;
    cursor: pointer;
}

.slider {
    width: 50px;
    height: 40px;
    background: #007bff;
    position: absolute;
}
</style>